import b from './Headline.module.scss';
import React, {memo} from "preact/compat";

const HEADLINE_AS = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    div: 'div',
};

const HeadlineAs = (props) => {
    return React.createElement(
      HEADLINE_AS[props.as],
      {
          className: [
              typeof props.className !== 'undefined' && props.className
          ].join(' '),
          ...props,
      },
      props.children,
    );
}

const Headline = memo(props => {
    const {
        as = "div",
        children,
        ...otherProps
    } = props;
    return (
      <HeadlineAs className={b.headline} as={as} {...otherProps}>
          {children}
      </HeadlineAs>
    );
});

export default Headline;
