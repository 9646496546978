import {compose, legacy_createStore as createStore} from 'redux';
import rootReducer from "./rootReducer";

const configureStore = () => {

    // Настройка для включения дебаггера
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(
      rootReducer,
      composeEnhancers(),
    );
};

export default configureStore;
