import b from './Button.module.scss';
import {memo} from "preact/compat";
import classNames from 'classnames/bind';
import Arrow from "./assets/arrow-right.svg?react";

let cx = classNames.bind(b);

const ButtonLink = memo((props) => {
    const {
        children,
        type,
        className,
        ...otherProps
    } = props;
    return (
      <a draggable={false} className={cx(className, {
          button: true,
          ['type-' + type]: true,
      })} {...otherProps}>
          <span className={b.text}>{children}</span>
          <span className={b.arrow}>
                <Arrow/>
          </span>
      </a>
    )
})

export default ButtonLink;
