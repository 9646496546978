import {render} from 'preact'
import {Provider} from 'react-redux';
import configureStore from "./store/configureStore.js";
import './styles/index.scss'
import Top from "./components/Top/Top.jsx";
import Header from "./components/Header/Header.jsx";
import Gifts from "./components/Gifts/Gifts.jsx";
import Footer from "./components/Footer/Footer.jsx";
import {setLinks} from "./store/links";
import {setCategories} from "./store/categories";
import {setTeams} from "./store/teams/index.js";
import {setProducts} from "./store/products/index.js";
import {setVideos} from "./store/videos/index.js";
import Videos from "./components/Videos/Videos.jsx";

const store = configureStore();
const context = window && window.context ? window.context : undefined;

const App = () => {
    store.dispatch(setVideos(context?.videos));
    store.dispatch(setLinks(context?.links));
    store.dispatch(setCategories(context?.categories));
    store.dispatch(setTeams(context?.teams));
    store.dispatch(setProducts(context?.products));
    return (
      <Provider store={store}>
          <div className="app">
              <Top/>
              <Header/>
              <Gifts/>
              <Videos/>
              <Footer/>
          </div>
      </Provider>
    );
}

render(
  <App/>,
  document.getElementById('app'))
