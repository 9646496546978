import {handleAction} from 'redux-actions';
import {SET_LINKS} from './actions';
import {combineReducers} from "redux";
import {nthArg, path, pipe} from "ramda";


const headerLink = handleAction(
  SET_LINKS,
  pipe(nthArg(1), path(['payload', 'header_link'])),
  null,
);

const productLink = handleAction(
  SET_LINKS,
  pipe(nthArg(1), path(['payload', 'product_link'])),
  null,
);

const footerLink = handleAction(
  SET_LINKS,
  pipe(nthArg(1), path(['payload', 'footer_link'])),
  null,
);

export default combineReducers({
    headerLink,
    productLink,
    footerLink
});
