import {handleActions} from 'redux-actions';
import {SET_PRODUCTS} from './actions';
import {combineReducers} from "redux";

const entities = handleActions(
  {
      [SET_PRODUCTS]: (_, {payload}) => payload
  },
  null,
)

export default combineReducers({
    entities
});
