import b from './Header.module.scss';
import {memo} from "preact/compat";
import HeaderSmallImage from './assets/header-image-small.png'
import HeaderSmallImage2x from './assets/header-image-small@2x.png'
import HeaderSmallImageWebp from './assets/header-image-small.png?format=webp&quality=80'
import HeaderSmallImage2xWebp from './assets/header-image-small@2x.png?format=webp&quality=80'
import HeaderImage from './assets/header-image.png'
import HeaderImage2x from './assets/header-image@2x.png'
import HeaderImageWebp from './assets/header-image.png?format=webp&quality=80'
import HeaderImage2xWebp from './assets/header-image@2x.png?format=webp&quality=80'
import ButtonLink from "../UI/ButtonLink/ButtonLink.jsx";
import Headline from "../UI/Headline/Headline.jsx";
import {useSelector} from "react-redux";
import {getHeaderLink} from "../../store/links/index.js";

const Header = memo((props) => {
    const headerLink = useSelector(getHeaderLink);
    return (
      <header className={b.container}>
          <div className="medium-padding large-padding">
              <div className="row small-collapse">
                  <div className="column">
                      <div className={b.inner}>
                          <div className={b.contentWrapper}>
                              <div className={b.headline}>
                                  <Headline as="h1">Меняй бонусы <br/>на <span
                                    className="accent">впечатления</span></Headline>
                              </div>
                              <div className={b.buttonContainer}>
                                  <ButtonLink href={headerLink} type="filled" draggable="false">
                                      Получить впечатления
                                  </ButtonLink>
                              </div>
                          </div>
                          <div className={b.background}>
                              <picture className="hide-for-medium" draggable={false}>
                                  <source srcSet={`${HeaderSmallImageWebp} 1x, ${HeaderSmallImage2xWebp} 2x`}
                                          type="image/webp"/>
                                  <source srcSet={`${HeaderSmallImage} 1x, ${HeaderSmallImage2x} 2x`} type="image/png"/>
                                  <img src={`${HeaderSmallImage}`} srcSet={`${HeaderSmallImage2x} 2x`} alt=""/>
                              </picture>
                              <picture className="show-for-medium" draggable={false}>
                                  <source srcSet={`${HeaderImageWebp} 1x, ${HeaderImage2xWebp} 2x`} type="image/webp"/>
                                  <source srcSet={`${HeaderImage} 1x, ${HeaderImage2x} 2x`} type="image/png"/>
                                  <img src={`${HeaderImage}`} srcSet={`${HeaderImage2x} 2x`} alt=""/>
                              </picture>
                          </div>
                          <div className={b.backgroundGlow}></div>
                      </div>
                  </div>
              </div>
          </div>
      </header>
    );
})

export default Header;
