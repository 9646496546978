import b from './CategoryList.module.scss'
import classNames from 'classnames/bind';
import {memo, useCallback} from "preact/compat";
import {useDispatch, useSelector} from "react-redux";
import {getCategories, getSelectedCategory, selectCategory} from "../../store/categories/index.js";

let cx = classNames.bind(b);

const CategoryList = memo((props) => {
    const dispatch = useDispatch();
    const categories = useSelector(getCategories);
    const selectedCategory = useSelector(getSelectedCategory);
    const handleClick = useCallback((id) => {
        dispatch(selectCategory(id));
    }, [dispatch]);
    return (
      <div className={b.container}>
          {categories.length > 0 ? (
            (categories.map(({id, name, icon}) => (
              <button onClick={() => handleClick(id)} key={id} className={cx({
                  category: true,
                  active: selectedCategory === id,
              })}>
                  <div className={b.categoryWrapper}>
                      <div className={b.categoryIcon} dangerouslySetInnerHTML={{__html: icon}}></div>
                      <div className={b.categoryTitle}>{name}</div>
                  </div>
              </button>
            )))
          ) : null}
      </div>
    );
});

export default CategoryList;
