import b from './Video.module.scss'
import {memo, useState} from "preact/compat";
import classNames from 'classnames/bind';
import ModalVideo from "react-modal-video";

let cx = classNames.bind(b);

const Video = memo(({index, video, cover}) => {
    const [isOpen, setOpen] = useState(false);
    return (
      <>
          <ModalVideo
            channel="youtube"
            isOpen={isOpen}
            videoId={video}
            onClose={() => setOpen(false)}
            classNames={{
                modalVideo: b.modalVideo,
                modalVideoClose: b.modalVideoClose,
                modalVideoBody: b.modalVideoBody,
                modalVideoInner: b.modalVideoInner,
                modalVideoIframeWrap: b.modalVideoIframeWrap,
                modalVideoCloseBtn: b.modalVideoCloseBtn,
            }}
          />
          <div onClick={() => setOpen(true)}
               className={cx({
                   card: true,
                   [`item-${index}`]: true,
               })}
               style={{backgroundImage: `url(${cover})`}}>
          </div>
      </>
    );
});

export default Video;
