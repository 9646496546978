import {combineReducers} from 'redux';
import videos from './videos';
import links from './links';
import categories from './categories';
import teams from './teams';
import products from './products';

export default combineReducers({
    videos,
    links,
    categories,
    teams,
    products
});
