import {createAction} from 'redux-actions';

// Action Types
export const SET_TEAMS = 'teams/SET_TEAMS';
export const SELECT_TEAM = 'teams/SELECT_TEAM';
export const SELECT_DEFAULT_TEAM = 'teams/SELECT_DEFAULT_TEAM';

// Action Creators
export const setTeams = createAction(SET_TEAMS);
export const selectTeam = createAction(SELECT_TEAM);
export const selectDefaultTeam = createAction(SELECT_DEFAULT_TEAM);

