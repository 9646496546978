import b from './GiftList.module.scss'
import 'swiper/css/pagination';
import {memo, useEffect, useRef, useState} from "preact/compat";
import {Swiper, SwiperSlide} from "swiper/react";
import Arrow from "./assets/arrow-right.svg?react";
import classNames from 'classnames/bind';
import {useSelector} from "react-redux";
import {getProductLink} from "../../store/links";
import {getProductsForTeam} from "../../store/products";
import {Navigation, Pagination} from "swiper/modules";
import {getSelectedTeam} from "../../store/teams/index.js";

let cx = classNames.bind(b);

export const GiftList = memo((props) => {
    const swiperRef = useRef(null);
    const selectedTeam = useSelector(getSelectedTeam);
    const products = useSelector(getProductsForTeam) || [];
    const productLink = useSelector(getProductLink);
    const [beginning, setBeginning] = useState(true);
    const [end, setEnd] = useState(false);
    const onReachBeginning = () => {
        setBeginning(true);
        setEnd(false);
    }
    const onReachEnd = () => {
        setBeginning(false);
        setEnd(true);
    }
    const onSlideChange = (swiper) => {
        const {isBeginning, isEnd} = swiper;
        if (isBeginning === false && isEnd === false) {
            setBeginning(true);
            setEnd(false);
        }
    }
    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.update();
            setBeginning(true);
            setEnd(false);
        }
    }, [selectedTeam]);

    return (
      <div className={b.container}>
          {products.length > 0 ? (
            <Swiper
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              modules={[Navigation, Pagination]}
              className={cx({
                  giftList: true,
                  shadowStart: end,
                  shadowEnd: beginning,
                  showShadow: products.length > 1
              })}
              watchSlidesProgress={true}
              grabCursor={true}
              spaceBetween={10}
              slidesPerView={'auto'}
              freeMode={true}
              pagination={{
                  enabled: true
              }}
              navigation={{
                  enabled: false
              }}
              breakpoints={{
                  640: {
                      pagination: {
                          enabled: false
                      },
                      navigation: {
                          enabled: true,
                      },
                      spaceBetween: 15,
                  },
                  1024: {
                      pagination: {
                          enabled: false
                      },
                      navigation: {
                          enabled: true,
                      },
                      spaceBetween: 30,
                  },
              }}
              onReachBeginning={onReachBeginning}
              onReachEnd={onReachEnd}
              onSlideChange={onSlideChange}
            >
                {products.map(({id, name, description, price, photo}) => (
                  <SwiperSlide key={id} className={cx({
                      giftWrapper: true,
                  })}>
                      <div className={cx({
                          giftInner: true,
                      })}>
                          <div className={b.gift}>
                              <div className={b.giftImage}>
                                  <img
                                    draggable={false}
                                    src={photo}
                                    alt=""
                                  />
                              </div>
                              <div className={b.giftContent}>
                                  <div className={b.giftTitle}>{name}</div>
                                  <div className={b.giftDescription}>
                                      {description}
                                  </div>
                              </div>
                              <div className={b.giftFooter}>
                                  <a className={b.giftButton} href={productLink}>
                                      <span className={b.giftButtonText}>Получить за бонусы</span>
                                      <span className={b.giftArrow}>
                                      <Arrow/>
                                  </span>
                                  </a>
                                  <span className={b.giftPrice}>{price}</span>
                              </div>
                          </div>
                      </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          ) : null}
      </div>
    );
})

export default GiftList;
