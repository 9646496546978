import b from './Videos.module.scss'
import {memo} from "preact/compat";
import Headline from "../UI/Headline/Headline.jsx";
import {useSelector} from "react-redux";
import {getVideos} from "../../store/videos";
import Video from "./Video.jsx";

const Videos = memo((props) => {
    const videos = useSelector(getVideos) || [];
    return (
      <div className={b.container}>
          <div className="medium-padding large-padding">
              <div className="row column">
                  <div className={b.headline}>
                      <Headline as="h2">Твои яркие впечатления вместе с Winline</Headline>
                  </div>
              </div>
          </div>
          <div className="medium-padding large-padding">
              <div className="row column">
                  {videos.length > 0 ? (
                    <div className={b.gridContainer}>
                        <div className={b.grid}>
                            {videos.map(({id, video, cover}, index) => (
                              <Video key={id}
                                     index={index}
                                     video={video}
                                     cover={cover}
                              />
                            ))}
                        </div>
                    </div>
                  ) : null}
              </div>
          </div>
      </div>
    );
});

export default Videos;
