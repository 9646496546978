import {handleActions} from 'redux-actions';
import {SELECT_CATEGORY, SET_CATEGORIES} from './actions';
import {combineReducers} from "redux";
import {nthArg, pathOr, pipe} from "ramda";

const entities = handleActions(
  {
      [SET_CATEGORIES]: (_, {payload}) => payload
  },
  null,
)

const selectedCategory = handleActions(
  {
      [SELECT_CATEGORY]: (_, {payload}) => payload,
      [SET_CATEGORIES]: pipe(nthArg(1), pathOr(null,['payload', '0', 'id'])),

  },
  null,
)

export default combineReducers({
    entities,
    selectedCategory
});
