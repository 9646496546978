import b from './Gifts.module.scss'
import 'swiper/scss';
import {memo} from "preact/compat";
import Headline from "../UI/Headline/Headline.jsx";
import CategoryList from "../CategoryList/CategoryList.jsx";
import TeamList from "../TeamList/TeamList.jsx";
import GiftList from "../GiftList/GiftList.jsx";
import {useSelector} from "react-redux";
import {getProductLink} from "../../store/links/index.js";

const Gifts = memo((props) => {
    return (
      <div className={b.container}>
          <div className="medium-padding large-padding">
              <div class="row column">
                  <div className={b.headline}>
                      <Headline as="h2">Уникальные подарки</Headline>
                      <Headline as="h4">Копи бонусы и меняй на свои впечатления</Headline>
                  </div>
              </div>
              <div class="row column">
                  <div class={b.categories}>
                      <CategoryList/>
                  </div>
              </div>
              <div class="row small-collapse medium-uncollapse position-relative">
                  <div className={b.backgroundGlow}></div>
                  <div class="column small-12 medium-4 large-5">
                      <div className={b.teamList}>
                          <h5>Список клубов-партнеров</h5>
                          <TeamList/>
                      </div>
                  </div>
                  <div class="column small-12 medium-8 large-7">
                      <div class={b.giftList}>
                          <GiftList/>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
})

export default Gifts;
