import {createAction} from 'redux-actions';

// Action Types
export const SET_CATEGORIES = 'categories/SET_CATEGORIES';
export const SELECT_CATEGORY = 'categories/SELECT_CATEGORY';

// Action Creators
export const setCategories = createAction(SET_CATEGORIES);
export const selectCategory = createAction(SELECT_CATEGORY);

