import b from './Footer.module.scss'
import {memo} from "preact/compat";
import ButtonLink from "../UI/ButtonLink/ButtonLink.jsx";
import VK from './assets/icons/vk.svg?react';
import TG from './assets/icons/tg.svg?react';
import {useSelector} from "react-redux";
import {getFooterLink} from "../../store/links/index.js";

const Footer = memo((props) => {
    const footerLink = useSelector(getFooterLink);
    return (
      <div className={b.container}>
          <div className="medium-padding large-padding">
              <div class="row column">
                  <div className={b.footer}>
                      <div className={b.footerButton}>
                          <ButtonLink href={footerLink} type="filled" draggable="false">
                              Получить впечатления
                          </ButtonLink>
                      </div>
                      <div className={b.footerSocialLinks}>
                          <a href="https://t.me/winline_channel">
                              <TG/>
                          </a>
                          <a href="https://vk.com/winline">
                              <VK/>
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
})

export default Footer;
