import {handleActions} from 'redux-actions';
import {SELECT_DEFAULT_TEAM, SELECT_TEAM, SET_TEAMS} from './actions';
import {combineReducers} from "redux";
import {nthArg, pathOr, pipe} from "ramda";

const entities = handleActions(
  {
      [SET_TEAMS]: (_, {payload}) => payload
  },
  null,
)

const selectedTeam = handleActions(
  {
      [SELECT_TEAM]: (_, {payload}) => payload,
      [SELECT_DEFAULT_TEAM]: pipe(nthArg(1), pathOr(null, ['payload', '0', 'id'])),
  },
  null,
)

export default combineReducers({
    entities,
    selectedTeam
});
