import {getProductsState} from "../moduleStateSelectors";
import {prop, pipe, converge, filter, propEq} from "ramda";
import {getSelectedCategory} from "../categories/index.js";
import {getSelectedTeam, getTeams} from "../teams/index.js";

export const getProducts = pipe(getProductsState, prop('entities'));

export const getProductsForTeam = converge(
  (products, team) => pipe(
    filter(propEq(team, 'team')),
  )(products),
  [getProducts, getSelectedTeam],
)
