import b from './TeamList.module.scss'
import {memo, useCallback, useEffect} from "preact/compat";
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Grid, Mousewheel, Scrollbar} from "swiper/modules";
import classNames from 'classnames/bind';
import 'swiper/scss/scrollbar';
import {useDispatch, useSelector} from "react-redux";
import {getSelectedTeam, getTeamsForCategory, selectDefaultTeam, selectTeam} from "../../store/teams";
import {getSelectedCategory} from "../../store/categories";

let cx = classNames.bind(b);

const TeamList = memo((props) => {
    const dispatch = useDispatch();
    const category = useSelector(getSelectedCategory);
    const teams = useSelector(getTeamsForCategory) || [];
    const selectedTeam = useSelector(getSelectedTeam);
    const handleClick = useCallback((id) => {
        dispatch(selectTeam(id));
    }, [dispatch]);
    useEffect(() => {
        dispatch(selectDefaultTeam(teams));
    }, [category]);
    return (
      <div class={b.container}>
          {teams.length > 0 ? (
            <Swiper
              className={cx({
                  teamList: true,
              })}
              modules={[Grid, Scrollbar, Mousewheel, FreeMode]}
              freeMode={true}
              mousewheel={{
                  enabled: true,
                  releaseOnEdges: true,
              }}
              scrollbar={{
                  enabled: true,
              }}
              spaceBetween={8}
              slidesPerView={'auto'}
              direction={'horizontal'}
              breakpoints={{
                  640: {
                      direction: 'vertical',
                      slidesPerView: 2,
                      spaceBetween: 10,
                      height: 450,
                      autoHeight: true,
                  },
                  1024: {
                      direction: 'vertical',
                      slidesPerView: 2,
                      spaceBetween: 30,
                      autoHeight: true,
                      grid: {
                          rows: 2,
                          fill: "column"
                      },
                  },
              }}
            >
                {teams.map(({id, name, origin, logo}) => (
                  <SwiperSlide onClick={() => handleClick(id)} key={id} className={b.teamWrapper}>
                      <div className={cx({
                          team: true,
                          active: selectedTeam === id,
                      })}>
                          <div className={b.teamLogo}>
                              <img
                                draggable={false}
                                src={logo}
                                alt=""
                                width={120} height={120}
                              />
                          </div>
                          <div className={b.teamName}>{name}</div>
                          <div className={b.teamOrigin}>{origin}</div>
                      </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          ) : null}
      </div>
    );
})

export default TeamList;
