import {getTeamsState} from "../moduleStateSelectors";
import {converge, filter, pipe, prop, propEq} from "ramda";
import {getSelectedCategory} from "../categories";

export const getTeams = pipe(getTeamsState, prop('entities'));
export const getTeamsForCategory = converge(
  (teams, category) => pipe(
    filter(propEq(category, 'type')),
  )(teams),
  [getTeams, getSelectedCategory],
)
export const getSelectedTeam = pipe(getTeamsState, prop('selectedTeam'));
