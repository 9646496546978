import b from './Top.module.scss'
import Logo from "./assets/logo.svg?react";
import {memo} from "preact/compat";
import ButtonLink from "../UI/ButtonLink/ButtonLink.jsx";

const Top = memo(() => {
    return (
      <div className={b.container}>
          <div className="medium-padding large-padding">
              <div className="row column">
                  <div className={b.topElements}>
                      <div className={b.logo}>
                          <a href="#">
                              <Logo/>
                          </a>
                      </div>
                      <div className={b.buttonContainer}>
                          <ButtonLink href="https://clck.ru/cVS7f" type="transparent" draggable="false">
                              <span className="show-for-medium">На сайт </span>winline.ru
                          </ButtonLink>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
})

export default Top;
